@mixin range-thumb {
  // https://css-tricks.com/sliding-nightmare-understanding-range-input/

  border: 1px solid $c-font-dimmer;
  height: 1em;
  width: 1.5em;
  border-radius: 12px;
  background: $c-bg-box;
  cursor: pointer;
}

@mixin range-track {
  width: 100%;
  height: 1em;
  cursor: pointer;
  background: $c-shade;
  border-radius: 0.5em;
}

input.range {
  -webkit-appearance: none;
  background: none;
  border: 0;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    @include range-track;
  }

  &::-webkit-slider-thumb {
    @include range-thumb;

    -webkit-appearance: none;
  }

  &::-moz-range-track {
    @include range-track;
  }

  &::-moz-range-thumb {
    @include range-thumb;
  }

  &::-ms-track {
    @include range-track;
  }

  &::-ms-fill-lower {
    background: rgba(191, 191, 191, 0.78);
    border-radius: 11.8px;
  }

  &::-ms-fill-upper {
    background: rgba(214, 214, 214, 0.78);
    border-radius: 11.8px;
  }

  &::-ms-thumb {
    @include range-thumb;

    border-color: transparent;
    color: transparent;
  }
}
