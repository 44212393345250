@import 'defs';
@import 'app/app';
@import 'table';
@import 'stream';
@import 'spotlight';
@import 'timeline';
@import 'box';
@import 'support';
@import 'about';
@import 'feed';
@import 'layout';

body {
  /* improves preload */
  overflow-y: scroll;
}

#main-wrap {
  ---main-max-width: 1400px;
}

%lobby__side__more {
  @extend %roboto;

  font-size: 0.9em;
  margin: 0.5em 1em 0.5em 0;
  display: block;
  text-align: right;
  color: $c-font-dim;

  &:hover {
    color: $c-link;
  }
}

.lobby__side {
  @extend %flex-column;

  overflow: hidden;
}

.lobby__nope {
  @include lobby-app-size;

  text-align: center;

  .lobby__app__content {
    @extend %flex-column;

    height: 100%;
    align-items: center;
    padding-top: 2em;

    &.lobby__app__content {
      justify-content: center;
    }
  }

  ul {
    margin: 0.6em auto;
    display: inline-block;
    text-align: left;
  }

  li {
    list-style: disc outside;
    font-size: 1.1em;
  }
}

.about-side {
  @extend %page-text-shadow !optional;

  margin-top: $block-gap;
}

.lobby__tv .mini-game {
  overflow: visible;
}

.lobby__puzzle .text {
  @extend %page-text-shadow !optional;

  color: $c-font-dim;
  display: block;
  text-align: right;
  margin-inline-end: 1ch;
}

.now-playing {
  .meta {
    @extend %flex-column;

    align-items: center;
  }
}

.lobby__blog {
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  grid-gap: 0 2%;
  grid-template-rows: auto; /* first row auto */
  grid-auto-rows: 0; /* all the other rows 0 */
  font-size: 0.9em;
  h2 {
    @extend %page-text-shadow !optional;
    @include line-clamp(4);
    font-size: 1.3em;
  }
  .ublog-post-card {
    background: $c-bg-box;
    &:hover {
      box-shadow: none;
    }
  }
}

.tour-team-icon {
  color: $c-brag;
}
