@keyframes glowing {
  42% {
    background: none;
  }

  50% {
    background: $m-primary_bg--mix-20;
  }

  58% {
    background: none;
  }
}

.glowing {
  animation: glowing 2s ease-in-out infinite;
}
