.lobby__spotlights {
  @extend %box-neat-force;

  flex: 0 0 auto;
}

.tour-spotlight {
  @extend %flex-center-nowrap, %page-text-shadow, %nowrap-hidden, %metal-bg;
  @include transition;

  flex: 0 0 auto;
  padding: 0.3em;
  opacity: 0.8;
  color: $c-font;

  @include if-light {
    background-image: linear-gradient(to bottom, $m-primary_bg--mix-20, rgb(197, 216, 234));
  }

  &.invert,
  &.event-spotlight,
  &:hover {
    @extend %metal-hover;
    text-shadow: none;

    @include if-light {
      background-image: linear-gradient(to bottom, $m-primary_bg--mix-25, $m-primary_bg-page--mix-20);
    }
  }

  &:hover {
    opacity: 1;
  }

  .img {
    flex: 0 0 42px;
    width: 44px;
    margin: 0 0.7em 0 0.4em;
  }

  i.img::before {
    color: #fff;
    font-size: 42px;

    @include if-light {
      text-shadow: 1px 1px 2px $c-link;
    }
  }

  img.img.icon {
    @include if-light {
      filter: drop-shadow(1px 1px 1px $c-link);
    }
  }

  .name {
    margin-top: 1px;
    line-height: 13px;
    display: block;
  }

  .headline {
    display: block;
    font-size: 0.85em;
    margin-bottom: -3px;
  }

  .more {
    font-size: 0.85em;

    time {
      margin-inline-start: 3px;
    }
  }

  &.little {
    .img {
      flex: 0 0 40px;
      margin: 0 0.7em 0 0.4em;

      &::before {
        font-size: 40px;
      }
    }
  }

  .tour-team-icon {
    margin-left: 1ch;
  }
}
