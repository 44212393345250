.lpools {
  @extend %roboto;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 9px;
  padding-top: 9px;
  background-color: transparent !important;
  box-shadow: none;
  overflow: visible !important; // for button shadows

  @include fluid-size('font-size', 14px, 25px);

  > div {
    @extend %flex-column, %box-radius, %break-word;

    justify-content: center;
    align-items: center;
    cursor: pointer;

    background: $c-pool-button;
    @include if-light {
      background: $m-bg--fade-50;
    }

    color: $c-font-dim;

    @include transition;

    &:hover {
      color: $c-font;
      background: $m-accent--fade-80 !important;
      opacity: 1;
    }
  }

  .active {
    @extend %popup-shadow;

    .perf {
      display: none;
    }
  }

  .transp {
    opacity: 0.35;
  }

  .spinner {
    flex: 0 0 auto;
    margin: 0.1em 0 0.6em 0;
    width: 2em;
    height: 2em;
    pointer-events: none;
  }

  .clock {
    display: block;
    font-size: 1.5em;
    line-height: 1.6em;
    letter-spacing: 0.1em;
  }
}
