@import '../theme/gen/wrap';

$site-hue: 37;
$border-width: 1px;
$border-style: solid;
$border: $border-width $border-style $c-border;

@mixin if-light {
  html.light & {
    @content;
  }
}

@mixin if-transp {
  html.transp & {
    @content;
  }
}

@mixin if-not-light {
  html:not(.light) & {
    @content;
  }
}

@mixin if-not-transp {
  html:not(.transp) & {
    @content;
  }
}

@mixin theme-style {
  html.transp::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    will-change: transform;
    z-index: -1;
  }
}
