@mixin mq-at-least-col2 {
  @media (min-width: at-least($xx-small)) {
    @content;
  }
}

@mixin mq-at-least-col3 {
  @media (min-width: at-least($small)) {
    @content;
  }
}

@mixin mq-at-least-col4 {
  @media (min-width: at-least($x-large)) {
    @content;
  }
}

@mixin lobby-app-size {
  min-height: 300px;

  @include mq-at-least-col2 {
    min-height: 400px;
  }

  @include mq-at-least-col3 {
    height: 600px;
    max-height: calc(100vh - #{$site-header-outer-height} - #{$block-gap});
  }
}
