.lobby__streams {
  .stream {
    @extend %roboto, %nowrap-ellipsis, %page-text;

    color: $c-font-page;
    display: block;
    margin-bottom: 0.5em;
    line-height: 0.9;

    strong {
      @extend %base-font;
    }

    &.highlight strong {
      color: $c-brag;
    }

    strong:before {
      font-size: 1.5em;
      margin-inline-end: 0.1rem;
    }
  }

  .more {
    @extend %lobby__side__more;
  }
}
