.color-icon {
  &.white::before {
    content: $licon-DiscBig;
  }

  &.black::before {
    content: $licon-DiscBigOutline;
  }
  @include if-light {
    &.white::before {
      content: $licon-DiscBigOutline;
    }

    &.black::before {
      content: $licon-DiscBig;
    }
  }

  &.random::before {
    content: $licon-RandomColor;
  }
}
